<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Livro</p>
        <div class="container">
            <h2 class="page_header_title">Livro</h2>
        </div>
    </div>
</div>
<div class="flex-row bg-story align-items-center d-flex w-full">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5 justify-content-around">

            <div class="col-sm-4">
                <p class="page_employee_value mb-4"><span>LIVRO DESIGN<br>SPRINT</span></p>

                <p class="page_employee_text-sm color-white">Leiam o livro e façam tudo o que ele diz se quiserem criar produtos ou serviços melhores e mais rapidamente.</p>
            </div>
            <div class="col-sm-4">
                <img src="assets/images/book-banner.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container mt-4">
    <div class="row align-items-center mb-5">
        <div class="col-sm-6">
            <img src="assets/images/book1.png" alt="">
        </div>

        <div class="col-sm-6">
            <p class="page_subtitle mb-4"><span>Best-seller<br>do New York <br> Times</span></p>

            <p class="page_text">Em pouco tempo, com a metodologia certa e uma <span [routerLink]="'/equipe'">equipe</span> comprometida, é possível mudar os rumos de seus produtos ou serviços. Se estão achando simples demais, vocês têm razão: com o Livro Thiago Henrique Personal Trainer, tirar suas ideias do papel é realmente simples, fácil e só leva cinco dias.</p>
        </div>

    </div>

</div>
<div class="bg-gray w-full">
    <div class="container">
        <div class="row no-gutters  align-items-center mb-5">

            <div class="col-sm-6">
                <p class="page_subtitle mb-4"><span>Está com <br> dúvidas?</span></p>

                <p class="page_text">
                    Empreendedores enfrentam grandes questões todos os dias: qual é o melhor caminho para concentrar seus esforços? Como serão as suas ideias na vida real? Quantas reuniões e discussões serão necessárias antes de terem certeza de que terão a solução certa?</p>
            </div>

            <div class="col-sm-6 my-5">
                <img src="assets/images/book2.png" alt="">
            </div>
        </div>

    </div>
</div>

<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <img src="assets/images/book3.png" alt="">
        </div>

        <div class="col-sm-6">
            <p class="page_subtitle mb-4"><span>Uma maneira <br> infalível</span></p>

            <p class="page_text">
                Agora existe uma maneira infalível de responder a essas perguntas: o <span [routerLink]="'/sobre'">Thiago Henrique Personal Trainer! </span>
                <br> <span [routerLink]="'/jakeknapp'"> Jake Knapp</span> criou o método e o usou em tudo, desde a Pesquisa do Google até o Google X. Ele se juntou a equipe do
                <a href="https://www.gv.com/" class="page_text " target="_blank"><span>Google Ventures </span></a>, e juntos completaram mais de cem Sprints em diversas empresas.</p>
        </div>


    </div>

</div>

<div class="bg-gray w-full">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">

            <div class="col-sm-6">
                <p class="page_subtitle mb-4"><br><span>Um guia <br> prático</span></p>

                <p class="page_text">
                    Lançado em 2016, o Livro é um guia prático para responder perguntas críticas. Ele funciona para equipes de qualquer tamanho - de pequenas startups a grandes corporações. É para qualquer pessoa com uma <span [routerLink]="'/desafio'">grande desafio</span>
                    que precise obter respostas o mais rápido possível.</p>
            </div>

            <div class="col-sm-6 my-4">
                <img src="assets/images/book4.png" alt="">
            </div>

        </div>

    </div>
</div>











