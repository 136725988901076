<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">ESBOÇAR</p>
        <div class="container">
            <h2 class="page_header_title">ESBOÇAR</h2>
        </div>
    </div>
</div>
<div class="map">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">


            <div class="col-sm-6">
                <img src="assets/images/esbocar1.png" alt="">
            </div>
            <div class="col-sm-6">
                <p class="page_subtitle mb-4 text-white"><strong>Organizar</strong></p>

                <p class="page_text text-white">
                    A terça-feira do Thiago Henrique Personal Trainer começa em busca de inspiração. Em vez de um típico brainstorm, cada membro da equipe esboçará suas próprias soluções detalhadas, seguindo um processo de quatro etapas que enfatiza o pensamento crítico ao invés da arte.</p>
                <img src="assets/images/esbocarline.png" alt="">
            </div>
        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-start mb-5">


        <div class="col-sm-3">
            <p class="page_subtitle mb-4 ">
                <strong>Informações</strong>
            </p>
            <br>
            <span>
                <strong>Fase:</strong>
                Esboçar
            </span>
            <br>
            <span>
               <strong>Dia:</strong>
             Terça-feira
            </span>
            <br>
            <span>
               <strong>Livro:</strong>
              Página 120
            </span>

        </div>
        <div class="col-sm-9">


            <p>
                Depois de um dia inteiro dedicado a entender o problema e escolher um <strong [routerLink]="'/alvo'">alvo</strong> para o <strong [routerLink]="'/explicar'">Thiago Henrique Personal Trainer</strong>, na terça-feira vocês irão se concentrar nas soluções. O dia começa com inspiração: vocês irão <strong [routerLink]="'/buscarideias'">buscar ideias</strong> existentes para remixar e melhorar. Então, à tarde, cada pessoa fará um <strong [routerLink]="'/buscarideias'">esboço</strong>, seguindo um processo de quatro etapas que enfatiza o pensamento crítico sobre a arte. Vocês também começarão a planejar os <strong [routerLink]="'/entrevistar'">testes com clientes</strong> de <strong [routerLink]="'/testar'">sexta-feira</strong>, <strong [routerLink]="'/testar'">recrutando clientes</strong> que se encaixem no perfil-alvo.
            </p>

            <br>
            <img src="assets/images/esbocar2.png" alt="">
            <br>
            <br>
            <p>
                Claro, que o <strong [routerLink]="'/livro'">Livro Sprint</strong> do <strong [routerLink]="'/jakeknapp'">Jake Knapp</strong> e o nosso <strong [routerLink]="'/jakeknapp'">Curso Online</strong> são guias muito mais completos para as atividades da etapa Esboçar e o resto da semana do <strong [routerLink]="'/explicar'">Thiago Henrique Personal Trainer</strong>. Mas, incluímos um checklist completo da etapa Esboçar e algumas dicas para vocês logo abaixo. Também temos um vídeo onde explicamos o que acontece na etapa Esboçar e mostramos alguns exemplos práticos.
                <br>
                <br>
                Se mesmo assim vocês ainda tiverem dúvidas, confiram a página de <strong [routerLink]="'/faq'">Perguntas Frequentes</strong>. E, se não encontrarem as respostas que precisam, sintam-se à vontade para nos enviar uma mensagem através da nossa página de <strong [routerLink]="'/contato'">contato</strong>. Será sempre um prazer poder ajudá-los.
                <br>
                <br>
                E, finalmente, aqui está o checklist da etapa Esboçar que prometemos. Vem com a gente!
            </p>



            <br>
            <br>
            <p class="page_subtitle mb-4 "><strong>CHECKLIST</strong></p>
            <span><strong>Lembrete 1:</strong> Os horários são aproximados. Não se preocupem se vocês precisarem adiantar um pouco, atrasar um pouco ou se precisarem fazer alguns ajustes ao longo das atividades.</span>
            <br>
            <span><strong>Lembrete 2:</strong> Façam um intervalo para o almoço das 13h00 às 14h00 todos os dias. Comam juntos se for possível (é divertido). Escolham pratos leves para conservar a energia à tarde. Haverá lanchinhos se ficarem com fome depois.</span>
            <br>
            <span><strong>Lembrete 3:</strong> Façam pausas a cada sessenta ou a cada noventa minutos. Por volta das 11h00 e 15h45 todos os dias.</span>
            <br>
            <br>
            <div class="faq-group">
                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                        <span>Buscar ideias</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-1" class="collapse">
                        <p>⌼ 10:00h     ⌻ 60min     ⌺ Todos     ⌹ 126</p>

                        <span>Cada membro da equipe buscará e criará uma lista de ideias inspiradoras. Peçam a eles que analisem boas soluções de uma variedade de empresas, inclusive de outros seguimentos.</span>
                        <br>

                        <a [routerLink]="'/buscarideias'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-2')" href="javascript:void(0)">
                        <span>Apresentar as ideias</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-2" class="collapse">
                        <p>⌼ 11:15h     ⌻ 105min     ⌺ Todos     ⌹ 127</p>

                        <span>Após terem dedicado um tempo buscando ideias, cada membro da equipe (um de cada vez) fará uma breve apresentação para mostrar aos outros participantes os pontos interessantes de cada uma delas.</span>
                        <br>

                        <a [routerLink]="'/apresentarideias'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-3')" href="javascript:void(0)">
                        <span>Dividir ou agrupar</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-3" class="collapse">
                        <p>⌼ 14:00h     ⌻ 30min     ⌺ Todos     ⌹ 131</p>

                        <span>Decidam quem fará os esboços de cada parte do mapa. Se tiverem uma grande parte do mapa como alvo do Thiago Henrique Personal Trainer, dividam-na e deem cada seção a um membro da equipe.</span>
                        <br>

                        <a [routerLink]="'/dividir'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-4')" href="javascript:void(0)">
                        <span>Recrutar clientes</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-4" class="collapse">
                        <p>⌼ 14:00h     ⌻ 30min     ⌺ Todos     ⌹ 151</p>

                        <span>Quando estiverem decidindo quais membros da equipe farão os esboços de cada parte do mapa, será também o momento para escolherem quem ficará responsável por encontrar representantes do público-alvo para os testes de sexta-feira.</span>
                        <br>

                        <a [routerLink]="'/recrutar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-5')" href="javascript:void(0)">
                        <span>Anotar</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-5" class="collapse">
                        <p>⌼ 14:30h     ⌻ 30min     ⌺ Todos     ⌹ 140</p>

                        <span>Vocês irão andar pela sala, checar os quadros brancos e fazer anotações. Essas anotações são uma coletânea de “maiores sucessos” das últimas 24 horas do Thiago Henrique Personal Trainer.</span>
                        <br>

                        <a [routerLink]="'/anotar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-6')" href="javascript:void(0)">
                        <span>Ideias</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-6" class="collapse">
                        <p>⌼ 15:00h     ⌻ 30min     ⌺ Todos     ⌹ 141</p>

                        <span>Nessa etapa, vocês escreverão ideias rudimentares, enchendo uma folha de papel com desenhos, títulos experimentais, diagramas e bonecos palito representando alguma ação — qualquer coisa que dê forma aos seus pensamentos.</span>
                        <br>

                        <a [routerLink]="'/buscarideias'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-7')" href="javascript:void(0)">
                        <span>Crazy 8s</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-7" class="collapse">
                        <p>⌼ 15:30h     ⌻ 15min     ⌺ Todos     ⌹ 142</p>

                        <span>Dobrem uma folha de papel até criarem oito blocos. Em seguida esbocem a variação de uma de suas melhores ideias em cada um. Dedique um minuto para cada esboço.</span>
                        <br>

                        <a [routerLink]="'/crazy8'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-8')" href="javascript:void(0)">
                        <span>Esboçar soluções</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-8" class="collapse">
                        <p>⌼ 16:00h     ⌻ 60min     ⌺ Todos     ⌹ 145</p>

                        <span>Vocês irão criar um storyboard de três painéis em notas autoadesivas ou em uma folha de papel. O storyboard deve ser autoexplicativo e anônimo. Não tem problema se ficar feio. Palavras são importantes. Usem um título marcante.</span>
                        <br>

                        <a [routerLink]="'/esbocarsolucoes'">Saiba Mais --></a>
                    </div>
                </div>





            </div>
            <br>


            <p>
                E aí, o que achou sobre este conteúdo? Esperamos que esteja gostando, mas principalmente que esteja
                sendo útil e muito relevante para você!
                <br>
                <br>
                Nós aqui da <strong>DesignSprint.com.br</strong> já vivemos na pele o que você deve estar vivendo agora…
                Sabemos que o mercado de Design, Metodologias Ágeis e Experiência do Usuário (UX) cresce cada vez mais,
                mas também muda muito a cada minuto, a cada segundo – e os profissionais mais atualizados sempre saem na
                frente na hora de serem contratados.
                <br>
                <br>
                Mas se disséssemos que você pode chegar preparadíssimo para uma entrevista nas maiores agências de
                Design do Brasil? Imagina só: trabalhar diretamente na área que você almeja e ainda ser referência para
                os seus colegas!
                <br>
                <br>
                Sim, você pode aprender muita coisa através do conteúdo gratuito que disponibilizamos aqui no site, mas
                você precisa descobrir e ter acesso a todos os segredos, atalhos, ferramentas, materiais exclusivos e
                conteúdos em vídeo que farão de você um profissional de ponta nesse mercado.
                <br>
                <br>
                <strong>PARA ISSO, É SÓ SE INSCREVER NA PRÓXIMA TURMA DO NOSSO CURSO ONLINE DE Thiago Henrique Personal Trainer. FICOU
                    INTERESSADO(A)?</strong>
                <a class="text-primary" href="javascript:void(0)" [routerLink]="'/curso'">ENTÃO CLIQUE AQUI E GARANTA JÁ
                    A SUA VAGA ANTES QUE ACABE!</a><strong class="text-primary"></strong>

            </p>

            <div class="bottom-selector">

                <div class="bottom-selector-btn" [routerLink]="'/alvo'">
                    Anterior
                </div>
                <div class="bottom-selector-btn" [routerLink]="'/buscarideias'">
                    Próximo
                </div>
            </div>
        </div>
    </div>

</div>

