<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Planos de Consultoria</p>
        <div class="container">
            <h2 class="page_header_title">Planos de Consultoria</h2>
        </div>
    </div>
</div>
<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">
            <p class="page_subtitle mb-4">O que é  <br><span>  Thiago Henrique Personal Trainer?</span> </p>

            <p class="page_text">Planos de Consultoria Semestral</p>
        </div>
        <div class="col-sm-6">
            <img src="assets/images/about1.png" alt="">
        </div>
    </div>

</div>
<div class="container">
    <div class="row no-gutters align-items-center mb-5">


        <div class="col-sm-6">
            <img src="assets/images/about2.png" alt="">
        </div>
        <div class="col-sm-6">



        </div>
    </div>

</div>

<div class="container">
    <div class="row no-gutters align-items-center mb-5">

        <div class="col-sm-6">

        </div>
        <div class="col-sm-6">
            <img src="assets/images/about3.png" alt="">
        </div>

    </div>

</div>




<section class="page-padding how d-none">
    <div class="row justify-content-center">
        <p class="page_subtitle text-center mb-4 text-white">Como são os dias do <br><span class="text-white">  Thiago Henrique Personal Trainer</span> </p>
        <div class="row">
        <div class="col-md-4 card-about">
            <span class="card-about-title">Organizar</span>
            <img src="assets/images/aboutline1.png" alt="">
            <span class="text-white">
                Antes de iniciar vocês precisarão ter a equipe certa e o desafio certo. Também precisarão de tempo e espaço para conduzir um Thiago Henrique Personal Trainer perfeito.
            </span>
            <div class="about-btn" [routerLink]="'/organizar'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Mapear</span>
            <img src="assets/images/aboutline2.png" alt="">
            <span class="text-white">
                A segunda-feira do Thiago Henrique Personal Trainer é uma série de conversas estruturadas para construir uma base – e um foco – para a semana. A estrutura permite que a equipe compartilhe o máximo de informações o mais rápido possível, evitando as habituais conversas sinuosas.
            </span>
            <div class="about-btn" [routerLink]="'/mapear'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Esboçar</span>
            <img src="assets/images/aboutline3.png" alt="">
            <span class="text-white">
               A terça-feira do Thiago Henrique Personal Trainer começa em busca de inspiração. Em vez de um típico brainstorm, cada membro da equipe esboçará suas próprias soluções detalhadas, seguindo um processo de quatro etapas que enfatiza o pensamento crítico ao invés da arte.
            </span>
            <div class="about-btn" [routerLink]="'/esbocar'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Decidir</span>
            <img src="assets/images/aboutline4.png" alt="">
            <span class="text-white">
                Na quarta-feira do Thiago Henrique Personal Trainer, vocês terão uma pilha de soluções. Agora, vocês deverão decidir quais desses esboços devem ser prototipados e testados.
            </span>
            <div class="about-btn" [routerLink]="'/decidir'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Prototipar</span>
            <img src="assets/images/aboutline5.png" alt="">
            <span class="text-white">
                Na quarta-feira, vocês criaram um storyboard. Na quinta-feira, adotarão a filosofia do “finjam” para transformar esse storyboard em um protótipo realista.
            </span>
            <div class="about-btn"  [routerLink]="'/prototipar'">
                Quero Saber Mais
            </div>
        </div>
        <div class="col-md-4 card-about">
            <span class="card-about-title">Testar</span>
            <img src="assets/images/aboutline6.png" alt="">
            <span class="text-white">
                Na sexta-feira do Thiago Henrique Personal Trainer, vocês mostrarão o protótipo para cinco clientes em cinco entrevistas individuais. Em vez de esperarem por um lançamento para obterem insights, vocês obterão as respostas que precisam imediatamente.
            </span>
            <div class="about-btn" [routerLink]="'/testar'">
                Quero Saber Mais
            </div>
        </div>
        </div>

    </div>
</section>



