<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">PROTOTIPAR</p>
        <div class="container">
            <h2 class="page_header_title">PROTOTIPAR</h2>
        </div>
    </div>
</div>
<div class="map">
    <div class="container">
        <div class="row no-gutters align-items-center mb-5">


            <div class="col-sm-6">
                <img src="assets/images/proto1.png" alt="">
            </div>
            <div class="col-sm-6">
                <p class="page_subtitle mb-4 text-white"><strong>PROTOTIPAR</strong></p>

                <p class="page_text text-white">
                    Na quarta-feira, vocês criaram um storyboard. Na quinta-feira, adotarão a filosofia do “finjam” para transformar esse storyboard em um protótipo realista.
                </p>
                <img src="assets/images/protoline.png" alt="">
            </div>
        </div>

    </div>
</div>


<div class="container">
    <div class="row no-gutters align-items-start mb-5">


        <div class="col-sm-3">
            <p class="page_subtitle mb-4 ">
                <strong>Informações</strong>
            </p>
            <br>
            <span>
                <strong>Fase:</strong>
                Prototipar
            </span>
            <br>
            <span>
               <strong>Dia:</strong>
             Quinta-feira
            </span>
            <br>
            <span>
               <strong>Livro:</strong>
              Página 156
            </span>

        </div>
        <div class="col-sm-9">


            <p>
                Na quinta-feira, vocês construirão um <strong [routerLink]="'/comoprototipar'">protótipo</strong> realista das cenas que preencheram no <strong [routerLink]="'/storyboard'">storyboard</strong> para simular um produto ou serviço acabado para os clientes. A prototipagem do <strong  [routerLink]="'/explicar'">Thiago Henrique Personal Trainer</strong> tem tudo a ver com uma filosofia "finjam até conseguirem": com um protótipo de aparência realista, vocês obterão os melhores dados possíveis do <strong [routerLink]="'/testar'">teste</strong> de <strong  [routerLink]="'/testar'">sexta-feira</strong> e saberão se estão no caminho certo.
            </p>

            <br>
            <img src="assets/images/proto2.png" alt="">
            <br>
            <br>
            <p>
                Claro, que o <strong [routerLink]="'/livro'">Livro Sprint</strong>  do <strong [routerLink]="'/jakeknapp'">Jake Knapp</strong>  e o nosso  <strong [routerLink]="'/curso'">Curso Online</strong> são guias muito mais completos para as atividades da etapa Decidir e o resto da semana do <strong [routerLink]="'/explicar'">Thiago Henrique Personal Trainer.</strong> Mas, incluímos um checklist completo da etapa Decidir e algumas dicas para vocês logo abaixo. Também temos um vídeo onde explicamos o que acontece na etapa Decidir e mostramos alguns exemplos práticos.
                <br>
                <br>
                Se mesmo assim vocês ainda tiverem dúvidas, confiram a página de <strong [routerLink]="'/faq'">Perguntas Frequentes</strong>. E, se não encontrarem as respostas que precisam, sintam-se à vontade para nos enviar uma mensagem através da nossa página de <strong [routerLink]="'/contato'">contato</strong>. Será sempre um prazer poder ajudá-los.
                <br>
                <br>
                E, finalmente, aqui está o checklist da etapa Decidir que prometemos. Vem com a gente!
            </p>

            <br>
            <br>
            <p class="page_subtitle mb-4 "><strong>CHECKLIST</strong></p>
            <span><strong>Lembrete 1:</strong> Os horários são aproximados. Não se preocupem se vocês precisarem adiantar um pouco, atrasar um pouco ou se precisarem fazer alguns ajustes ao longo das atividades.</span>
            <br>
            <span><strong>Lembrete 2:</strong> Façam um intervalo para o almoço das 13h00 às 14h00 todos os dias. Comam juntos se for possível (é divertido). Escolham pratos leves para conservar a energia à tarde. Haverá lanchinhos se ficarem com fome depois.</span>
            <br>
            <span><strong>Lembrete 3:</strong> Façam pausas a cada sessenta ou a cada noventa minutos. Por volta das 11h00 e 15h45 todos os dias.</span>
            <br>
            <br>
            <div class="faq-group">

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                        <span>Como prototipar</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-1" class="collapse">
                        <p>⌼ -     ⌻ -     ⌺ -     ⌹ 222</p>

                        <span>Cada protótipo é único, então não há um passo a passo específico. Porém, existem quatro exercícios que podem ajudar vocês a andarem no caminho certo.</span>
                        <br>

                        <a [routerLink]="'/comoprototipar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-2')" href="javascript:void(0)">
                        <span>Escolher a ferramenta</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-2" class="collapse">
                        <p>⌼ 10:00h     ⌻ 30min     ⌺ Todos     ⌹ 222</p>

                        <span>Não usem uma ferramenta habitual. Ferramentas habituais geralmente são feitas para um trabalho de alta qualidade. Em vez disso, usem uma ferramenta simples, rápida e flexível.</span>
                        <br>

                        <a [routerLink]="'/ferramenta'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-3')" href="javascript:void(0)">
                        <span>Dividir para conquistar</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-3" class="collapse">
                        <p>⌼ 10:30h     ⌻ 30min     ⌺ Todos     ⌹ 223</p>

                        <span>Distribuam papéis: Executor, Costureiro, Escritor, Coletor de recursos e Entrevistador. Vocês também podem dividir o storyboard em cenas menores e atribuírem cada uma a membros diferentes da equipe.</span>
                        <br>

                        <a [routerLink]="'/conquistar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-4')" href="javascript:void(0)">
                        <span>Iniciar o protótipo</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-4" class="collapse">
                        <p>⌼ 11:15h     ⌻ 180min     ⌺ Todos     ⌹ 203</p>

                        <span>Para fazer o protótipo, vocês vão precisar de uma mudança temporária de filosofia: do "perfeito" para "apenas o suficiente" e da "qualidade de longo prazo" para a "simulação temporária".</span>
                        <br>

                        <a [routerLink]="'/prototipo'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-5')" href="javascript:void(0)">
                        <span>Costurar o protótipo</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-5" class="collapse">
                        <p>⌼ 14:00h     ⌻ 45min     ⌺ Todos     ⌹ 226</p>

                        <span>Com o trabalho dividido em partes, é fácil se perder. O Costureiro deve checar a qualidade e assegurar que todas as peças façam sentido quando encaixadas.</span>
                        <br>

                        <a [routerLink]="'/costurar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-6')" href="javascript:void(0)">
                        <span>Revisar o protótipo</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-6" class="collapse">
                        <p>⌼ 16:00h     ⌻ 15min     ⌺ Todos     ⌹ 226</p>

                        <span>Com tudo finalizado revisem o protótipo, procurem erros e certifiquem-se de que o Entrevistador e o Definidor o vejam.</span>
                        <br>

                        <a [routerLink]="'/revisar'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-7')" href="javascript:void(0)">
                        <span>Escrever o roteiro</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-7" class="collapse">
                        <p>⌼ 16:15h     ⌻ 30min     ⌺ Entrevistador     ⌹ 225</p>

                        <span>Após revisar o protótipo junto com a equipe, o Entrevistador se prepara para o teste da sexta-feira escrevendo um roteiro com as perguntas que fará aos clientes.</span>
                        <br>

                        <a [routerLink]="'/roteiro'">Saiba Mais --></a>
                    </div>
                </div>

                <div class="mb-3 mbg">
                    <a class="faq-question" (click)="handlerCollapse('request-8')" href="javascript:void(0)">
                        <span>Confirmar as entrevistas</span>
                        <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                    </a>
                    <div id="request-8" class="collapse">
                        <p>⌼ 16:45h     ⌻ 15min     ⌺ Um integrante     ⌹ -</p>

                        <span>Agora que está tudo pronto, a ultima tarefa do dia é confirmar as entrevistas de sexta-feira com os clientes. Um e-mail serve, mas uma ligação é melhor.</span>
                        <br>

                        <a [routerLink]="'/confirmar'">Saiba Mais --></a>
                    </div>
                </div>





            </div>
            <br>


            <p>
                E aí, o que achou sobre este conteúdo? Esperamos que esteja gostando, mas principalmente que esteja
                sendo útil e muito relevante para você!
                <br>
                <br>
                Nós aqui da <strong>DesignSprint.com.br</strong> já vivemos na pele o que você deve estar vivendo agora…
                Sabemos que o mercado de Design, Metodologias Ágeis e Experiência do Usuário (UX) cresce cada vez mais,
                mas também muda muito a cada minuto, a cada segundo – e os profissionais mais atualizados sempre saem na
                frente na hora de serem contratados.
                <br>
                <br>
                Mas se disséssemos que você pode chegar preparadíssimo para uma entrevista nas maiores agências de
                Design do Brasil? Imagina só: trabalhar diretamente na área que você almeja e ainda ser referência para
                os seus colegas!
                <br>
                <br>
                Sim, você pode aprender muita coisa através do conteúdo gratuito que disponibilizamos aqui no site, mas
                você precisa descobrir e ter acesso a todos os segredos, atalhos, ferramentas, materiais exclusivos e
                conteúdos em vídeo que farão de você um profissional de ponta nesse mercado.
                <br>
                <br>
                <strong>PARA ISSO, É SÓ SE INSCREVER NA PRÓXIMA TURMA DO NOSSO CURSO ONLINE DE Thiago Henrique Personal Trainer. FICOU
                    INTERESSADO(A)?</strong>
                <a class="text-primary" href="javascript:void(0)" [routerLink]="'/curso'">ENTÃO CLIQUE AQUI E GARANTA JÁ
                    A SUA VAGA ANTES QUE ACABE!</a><strong class="text-primary"></strong>

            </p>

            <div class="bottom-selector">

                <div class="bottom-selector-btn" [routerLink]="'/storyboard'">
                    Anterior
                </div>
                <div class="bottom-selector-btn" [routerLink]="'/comoprototipar'">
                    Próximo
                </div>
            </div>
        </div>
    </div>

</div>

