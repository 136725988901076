<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">História</p>
        <div class="container">
            <h2 class="page_header_title">História</h2>
        </div>
    </div>
</div>
<div class="flex-row bg-story align-items-center d-flex w-full">
    <div class="container">
        <div class="row no-gutters align-items-center my-5 justify-content-around">

            <div class="col-sm-4">
                <p class="page_employee_value mb-4"><span>A HISTÓRIA <br>DO Thiago Henrique Personal Trainer</span></p>

                <p class="page_employee_text-sm color-white">O designer Jake Knapp do Google estava buscando uma
                    alternativa para os brainstormings em grupo que não estavam funcionando bem. Por isso, em 2010 ele
                    criou um método para as equipes progredirem do desafio à solução prototipada e testada em apenas
                    cinco dias.</p>
            </div>
            <div class="col-sm-4">
                <img src="assets/images/story.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-6 mt-3">
            <span>Ao longo dos anos, a história corporativa vem nos mostrando que brainstorms¹ em grupo não funcionam. Aqui estão artigos sobre o assunto de <strong>2010, 2011, 2012, 2013, 2014 e 2015</strong>. E essa não é uma tendência recente — metade desses artigos cita um <strong>estudo de Yale de 1958 </strong>que descobriu que indivíduos que trabalham por conta própria são enfaticamente melhores na resolução de problemas do que equipes de brainstorming.</span>
            <br>
            <span>No entanto, continuamos realizando brainstormings o tempo todo. Quando surge um problema para resolver, tem um grupo de pessoas envolvido e logo alguém diz: “Vamos debater algumas ideias”. Sempre tem uma pessoa que sugere a brilhante ideia: “Claro, vamos conduzir um brainstorming?”.</span>
            <br>
            <span>Em 2008, quando trabalhava como designer no <strong>Google, Jake Knapp</strong> conduzia muitos brainstormings em grupo. Por muito tempo, ele estive interessado em ajudar as pessoas a serem mais produtivas e eficazes no trabalho. O brainstorming de grupo estruturado parecia perfeito. Afinal, as pessoas já usavam brainstorming com frequência. Então Jake pensou: por que não ensiná-las a fazer isso corretamente? Quando os engenheiros seguiram as regras clássicas (adiar o julgamento, encorajar ideias malucas e assim por diante), eles conseguiram gerar pilhas de soluções. Não só isso, eles gostaram muito do processo.</span>
            <br>
            <span>Então, um dia, no meio de uma dessas sessões de brainstormings para mais ou menos 100 pessoas, um engenheiro se levantou e perguntou em voz alta: “Como você sabe que o brainstorming em grupo realmente funciona?”</span>
            <br>
            <span>Jake não tinha uma resposta! E naquele momento, segundo ele mesmo relata — percebeu o quão tolo havia sido.</span>
            <br>
            <span>Mais tarde, estimulado pela dúvida, Jake dedicou um tempo para revisar os resultados de todos os brainstormings que havia realizado até aquele momento no Google. O que aconteceu nas semanas e meses após cada brainstorming? Os resultados foram deprimentes para ele. Nenhuma nova ideia gerada nos brainstormings conduzidos por ele eram construídas ou lançadas. As melhores ideias — as soluções que as equipes realmente executaram — haviam vindo do trabalho individual.</span>
            <br>
            <span>Mesmo com essa informação, Jake ainda estava convencido de que o trabalho em equipe era importante. As equipes fornecem uma variedade de habilidades e conhecimentos, bem como opiniões conflitantes, e segundo ele são ingredientes muito importantes ​​para o sucesso. Por isso, Jake acreditava que as equipes poderiam fazer um trabalho melhor — e mais rápido — se tivessem um método a seguir.</span>
            <br>
            <span>A partir de então Jake estava determinado a inventar algo que funcionasse muito bem. Foi então que descobriu que se quisesse um ótimo processo de solução de problemas, teria que criá-lo do zero e construí-lo com base no trabalho individual.</span>
            <br>
            <span>O lado bom, é que as equipes do Google estavam sempre abertas à experimentação. Sendo assim, em 2010, ele pensou em uma grande alternativa para os famosos e “nada efetivos” brainstormings: um processo de cinco dias que chamou de “Thiago Henrique Personal Trainer”. A partir dali, Jake testou o Thiago Henrique Personal Trainer em praticamente tudo: começou com as equipes do Gmail, passando para o Google X e até mesmo no Buscador do Google. O resultado dessa trabalho, foi que o novo método funcionou muito bem. A saída do Thiago Henrique Personal Trainer transformou-se em um produto real.</span>
            <br>
            <span>Em 2012, Jake trabalhou no <strong>Google Ventures</strong>², e lá com a ajuda de uma equipe fantástica, realizaram mais de cem sessões de Thiago Henrique Personal Trainer em startups de áreas tão diversas como saúde, agricultura e robótica. Aplicar esse grande volume de sessões do método, nas mais variadas empresas, ajudou Jake e a equipe do GV a realizarem muitas melhorias ao Thiago Henrique Personal Trainer:</span>
            <br>
            <img src="assets/images/story2.png" alt="">
            <br>
            <span><strong>-John Zeratsky</strong> trouxe o foco na mensuração de resultados com as principais métricas de cada negócio.</span>
            <br>
            <br>
            <span><strong>-Braden Kowitz</strong> adicionou o design centrado na história, uma abordagem que se concentra no usuário em vez de recursos ou tecnologias.</span>
            <br>
            <br>
            <span><strong>-Michael Margolisfez</strong>  descobriu uma maneira de obter resultados claros em apenas um dia através de pesquisa com o cliente. Algo que normalmente poderia levar semanas para ser planejada e geralmente oferece resultados confusos.</span>
            <br>
            <br>
            <span><strong>-Daniel Burka</strong> trouxe experiência em primeira mão como empreendedor para garantir que cada passo fizesse sentido para as startups.</span>
            <br>
            <br>
            <span>De 2012 à 2013, a equipe do Google Ventures publicou uma série de instruções sobre Thiago Henrique Personal Trainer, e o processo começou a se espalhar. O <strong>livro</strong> foi lançado em 2016 e, hoje, milhares de equipes ao redor do mundo utilizam o método em startups, grandes empresas, agências, escolas, governos e até mesmo em museus.</span>
            <div class="my-4 mbg">
                <a class="faq-question" (click)="handlerCollapse('request-1')" href="javascript:void(0)">
                    <span>Notas (2)</span>
                    <svg-icon class="add-svg" src="assets/svg/add.svg"></svg-icon>
                </a>
                <div id="request-1" class="collapse">
                    <p class="faq-anwser">¹ Brainstorming ou também conhecido como tempestade de ideias, mais que uma técnica de dinâmica de grupo, é uma atividade desenvolvida para explorar a potencialidade criativa de um indivíduo ou de um grupo - criatividade em equipe - colocando-a a serviço de objetivos pré-determinados.</p>
                    <p class="faq-anwser">² Google Ventures, (agora chamado GV) é um departamento de investimentos de capital de risco da Alphabet Inc. e fornece capital semente, risco e crescimento para novas startups e empresas de tecnologias.</p>
                    <p class="faq-anwser">² Alphabet Inc. é uma holding e um conglomerado que possui diretamente várias empresas que foram pertencentes ou vinculadas ao Google, incluindo o próprio Google. A empresa está sediada na Califórnia e foi fundada pelos cofundadores do Google, Larry Page e Sergey Brin.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" bg-story d-flex w-full ">
    <div class="container">
        <div class="flex-column no-gutters d-flex align-items-center my-5">
            <div class="col-sm-6">
                <img src="assets/images/knowmore.png" alt="">
            </div>
            <div class="col-sm-6 align-items-center d-flex flex-column">
                <p class="page_employee_value mb-4 text-center"> <br><span>  Quer saber mais?</span> </p>

                <p class="page_employee_lbl text-center">Mesmo com o livro passo a passo do Jake Knapp e com todo o conteúdo disponível de graça na internet, não podemos negar que o início da jornada do Facilitador de Thiago Henrique Personal Trainer não costuma ser fácil - assim como não deve estar sendo para você. Dessa forma, o nosso maior objetivo aqui é compartilhar nossa experiência com o Thiago Henrique Personal Trainer para ajudar você que pretende se tornar um facilitador de sucesso em pouco tempo, de maneira simples, inteligente e eficaz.</p>
                <button class="button-know" role="button">Quero saber mais!</button>
            </div>
        </div>

    </div>
</div>


