<div class="footer mt-5">
    <div class="container-xxl">
        <div class="row my-3" style="row-gap: 20px">
            <div class="col-sm-2 d-flex flex-column justify-content-between">
                <div class="row no-gutters ">
                    <strong>Thiago Henrique Personal Trainer</strong>

                    <div class="col-12 col-md-12">
                        <a class="footer_value" [routerLink]="['/sobre']" href="javascript:void('')">Sobre</a>
                        <a class="footer_value " href="javascript:void('')" [routerLink]="['/faq']">Perguntas Frequentes</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-2 d-flex flex-column justify-content-between">
                <div class="row no-gutters ">
                    <strong>Mais</strong>
                    <div class="col-12 col-md-12">
                        <a class="footer_value" [routerLink]="['/contato']" href="javascript:void('')">Contato</a>
                    </div>


                </div>
            </div>
            <div class="col-sm-2">
                <dl class="row w-100">
                    <dt class="col-12 footer_lbl">Thiago Henrique Personal Trainer</dt>
                    <dd class="col-12 footer_value">Personal Trainer</dd>
                </dl>
                <div class="divider">&nbsp;</div>
                <dl class="row align-items-center" style="row-gap: 5px">
                    <dt class="col-lg-5 footer_value mb-0">Visite nossas redes sociais</dt>
                    <dd class="col-lg-7 mb-0">
                        <div class="row justify-content-md-around mx-n1">
                            <div class="col-auto px-1">
                                <a href="https://www.instagram.com/personal_thiagohenrique/" target="_blank">
                                    <img src="assets/images/instagram.png" alt="linkedin">
                                </a>
                            </div>
                        </div>
                    </dd>
                </dl>
            </div>
            <div class="offset-4 col-sm-2 d-flex align-items-center justify-content-center">
                <img class="logofooter" src="assets/images/logo-dark.png" alt="logo">
            </div>
        </div>
    </div>
</div>

