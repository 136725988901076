<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Cadastre sua equipe</p>
        <div class="container">
            <h2 class="page_header_title">Cadastre <span>sua equipe</span></h2>
        </div>
    </div>
</div>

<div class="container">
    <div class="row justify-content-center justify-content-xl-between align-items-center my-5" style="row-gap: 30px">
        <div class="col-lg-6">
            <p class="page_text page_text-lg mb-5">
                Caso você tenha cadastrado sua equipe
                na campanha anterior, ela aparecerá automaticamente pra facilitar o processo para você. Porém, é preciso
                conferir cada colaborador atualizando seus dados, validando-os ou excluindo-os.
            </p>
            <div class="row justify-content-end">
                <div class="col-sm-7">
                    <p class="page_text">
                        <span>Cadastrar o CPF corretamente é fundamental</span>
                        para que todos possam receber o prêmio! Caso você
                        não possua uma lista pré-cadastrada, inclua sua equipe agora mesmo!
                    </p>
                </div>
                <div class="col-8 col-sm-5">
                    <a class="btn btn-block btn-secondary btn-user" (click)="actionNew()">
                        <div class="row no-gutters">
                            <div class="col-6">
                                <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/add-circle.svg"></svg-icon>
                                <p class="mt-2 mb-0">Adicionar<br>funcionário</p>
                            </div>
                            <div class="col-6">
                                <div class="btn-user_icon">
                                    <img src="assets/images/btn-user.png" alt="user">
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <h3 class="page_title page_title-lg mb-4">FUNCIONÁRIOS</h3>
</div>

<div class="step-gradient">
    <div class="container">
        <div class="row">
            <div class="col-sm bg-secondary">
                <div class="d-flex py-5">
                    <p class="page_employee_text mb-0">ORIENTE SEUS FUNCIONÁRIOS A ACESSAREM O SITE DO
                        DESAFIO Thiago Henrique Personal Trainer.</p>
                </div>
            </div>
            <div class="col-lg-6 bg-white">
                <div class="py-4 px-3 px-xl-5 mx-auto page_employee_form">
                    <p class="page_text"> No primeiro acesso ao site do Desafio, o <span class="text-primary">membro da equipe</span>
                        deve inserir os seguintes dados: </p>
                    <div class="input-material">
                        <input type="text" class="form-control" id="document" autocomplete="off"
                               [value]="'Xxx.xxx.xxx-xx (CPF)'"
                               name="document" maxlength="100" required disabled/>
                        <label class="disabled" for="document">Login</label>
                    </div>
                    <div class="input-material mb-0">
                        <input type="text" class="form-control" id="password" autocomplete="off"
                               [value]="'(4 primeiros dígitos do seu CPF)'"
                               maxlength="50" name="password" required disabled/>
                        <label class="disabled" for="password">Senha</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container py-5">
    <div class="row no-gutters align-items-center mb-3" style="row-gap: 20px">
        <div class="col">
            <h3 class="page_title">Lista de Equipe</h3>
        </div>

        <div class="col-auto">
            <a class="btn btn-block btn-secondary btn-user" (click)="actionNew()">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/add-circle.svg"></svg-icon>
                    </div>
                    <div class="col-auto d-none d-sm-block mr-auto">
                        <p class="text-left mb-0">Adicionar funcionário</p>
                    </div>
                    <div class="col-auto d-none d-sm-block">
                        <div class="btn-user_icon_secondary">
                            <img src="assets/images/btn-user.png" alt="user">
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <div class="table-responsive mt-4">
        <table class="table table-resale table-striped">
            <thead>
            <tr>
                <th scope="col">Nome</th>
                <th scope="col">E-mail</th>
                <th scope="col" class="text-center">Data de Nascimento</th>
                <th scope="col">{{'CPF'|translate}}</th>
                <th scope="col">{{'Telefone'|translate}}</th>
                <th scope="col" class="text-center">{{'Cargo'|translate}}</th>
                <th scope="col" class="text-center">Status</th>
                <th scope="col" class="text-center">Ação</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="this.isNullOrUndefined(userPage.results)">
                <td class="text-center" colspan="8">Nenhum Funcionário encontrado</td>
            </tr>
            <tr *ngFor="let x of userPage.results|
            paginate:{id:'users',itemsPerPage: userPage?.pageSize,
            currentPage: userPage?.currentPage,
            totalItems: userPage?.totalCount }">
                <td>{{x.name}}</td>
                <td>{{x.email}}</td>
                <td class="text-center">{{x.birthday}}</td>
                <td>{{x.cpf | mask:'000.000.000-00||00.000.000/0000-00'}}</td>
                <td>{{x.phone | mask:x.phone?.length > 10 ? '(00) 0 0000-0000' : '(00) 0000-0000'}}</td>
                <td class="text-center"><!--{{x.profile?.name}}--></td>
                <td class="text-center">
                    <!--<svg-icon class="justify-content-center" *ngIf="x.isValid"
                              src="assets/svg/check-circle.svg"></svg-icon>-->

                </td>
                <td class="text-center">
                    <div class="d-flex justify-content-center" style="gap: 15px">
                        <a class="cursor-pointer" (click)="actionEdit(x)">
                            <svg-icon src="assets/svg/edit.svg"></svg-icon>
                        </a>

                        <a class="cursor-pointer" (click)="actionDelete(x.id)">
                            <svg-icon src="assets/svg/cancel.svg"></svg-icon>
                        </a>
                    </div>

                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="row justify-content-between" style="row-gap:20px">
        <div class="col-sm-auto">
            <app-pagination-custom
                    [id]="'users'"
                    (pageChange)="pageChanged($event)"
            ></app-pagination-custom>
        </div>
        <div class="col-sm-5 order-sm-first">
            <dl class="row" style="row-gap: 20px">
                <dt class="page_employee_legend col-sm-auto">Legenda</dt>
                <dd class="col-sm">
                    <div class="row no-gutters justify-content-between">
                        <div class="col-auto col-sm">
                            <div class="row align-items-center mx-n1">
                                <div class="col-sm-auto px-1">
                                    <div class="switch mb-0">
                                        <span class="slider round" style="cursor: initial"></span>
                                    </div>
                                </div>
                                <div class="col px-1">
                                    <p class="page_legend mb-1">Validar</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto col-sm">
                            <div class="row align-items-center mx-n1">
                                <div class="col-sm-auto px-1">
                                    <svg-icon src="assets/svg/check-circle.svg"></svg-icon>
                                </div>
                                <div class="col px-1">
                                    <p class="page_legend mb-0">Validado</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto col-sm">
                            <div class="row align-items-center mx-n1">
                                <div class="col-sm-auto px-1">
                                    <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                </div>
                                <div class="col px-1">
                                    <p class="page_legend mb-0">Pendente</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto col-sm">
                            <div class="row align-items-center mx-n1">
                                <div class="col-sm-auto px-1">
                                    <svg-icon src="assets/svg/cancel.svg"></svg-icon>
                                </div>
                                <div class="col px-1">
                                    <p class="page_legend mb-0">Excluir</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </dd>
            </dl>
        </div>
    </div>
</div>

<!--MODAL USER-->
<div class="modal fade" id="modalUser" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <div class="modal-title modal_title">
                    {{this.isNullOrUndefined(this.modelUser.id) ? 'NOVO FUNCIONÁRIO' : 'EDIÇÃO DE FUNCIONÁRIO'}}
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body--small">
                <p class="modal_subtitle">Preencha os campos abaixo com os dados pessoais do funcionário(a).</p>
                <div class="row my-3">
                    <!--<div class="col-lg-6">
                        <div class="input-material select">
                            <select [(ngModel)]="profileId" [disabled]="!this.isNullOrUndefined(this.modelUser.id)"
                                    class="form-control" id="cargo" name="cargo" required>
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let p of profiles" [ngValue]="p.id">{{p.name}}</option>
                            </select>
                            <label for="cargo"
                                   [ngClass]="{'disabled':!this.isNullOrUndefined(this.modelUser.id)}">Aréa*</label>
                        </div>
                    </div>-->
                    <!--<div class="col-lg-6">
                        <div class="input-material select">
                            <select [(ngModel)]="resaleJobRoleId" class="form-control" id="resaleJobRoleId"
                                    name="resaleJobRoleId" required>
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let p of resaleJobRoles" [ngValue]="p.id">{{p.name}}</option>
                            </select>
                            <label for="resaleJobRoleId">Cargo*</label>
                        </div>
                    </div>-->
                    <div class="col-lg-6">
                        <div class="input-material">
                            <input type="text" class="form-control" id="cpf" [(ngModel)]="modelUser.cpf"
                                   [disabled]="!this.isNullOrUndefined(this.modelUser.id)"
                                   maxlength="100" name="cpf" [dropSpecialCharacters]="false"
                                   mask="000.000.000-00"
                                   required/>
                            <label for="cpf"
                                   [ngClass]="{'disabled':!this.isNullOrUndefined(this.modelUser.id)}">CPF*</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <input type="text" class="form-control" id="name" [(ngModel)]="modelUser.name"
                                   maxlength="100" name="name" required/>
                            <label for="name">Nome Completo*</label>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="input-material">
                            <input class="form-control" type="text" id="birthday" name="birthday"
                                   [mask]="'d0/M0/0000'"
                                   [(ngModel)]="modelUser.birthday"
                                   [dropSpecialCharacters]="false"
                                   required/>
                            <label for="birthday">Data de Nascimento*</label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="input-material">
                            <select class="form-control" id="celType" name="celType" [(ngModel)]="modelPhoneType">
                                <!--<option [ngValue]="undefined">Selecione</option>-->
                                <option selected [ngValue]="'celular'">Celular</option>
                                <option [ngValue]="'fixo'">Fixo</option>
                            </select>
                            <label for="cel">Tipo de Telefone* {{modelPhoneType}}</label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="input-material">
                            <input type="text" class="form-control" id="cel"
                                   [(ngModel)]="modelUser.phone"
                                   [clearIfNotMatch]="true"
                                   [mask]="(!isNullOrUndefined(modelPhoneType) && modelPhoneType === 'fixo')
                                    || (modelUser.phone?.length < 11 && modelPhoneType !== 'celular') ? '(00) 0000-0000' : '(00) 0 0000-0000'"
                                   maxlength="100" name="cel" required/>
                            <label for="cel">Telefone com DDD*</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-material">
                            <input type="text" class="form-control" id="email" maxlength="100" name="email"
                                   [(ngModel)]="modelUser.email" required/>
                            <label for="email">E-mail*</label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="input-material">
                            <input type="text" class="form-control" id="confimEmail" maxlength="100" name="confimEmail"
                                   [(ngModel)]="emailConfirm" required/>
                            <label for="confimEmail">Confirmar E-mail*</label>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between mt-3">
                    <div class="col-6 col-sm-4">
                        <a data-dismiss="modal" aria-label="Close"
                           class="btn btn-block btn-outline-secondary">Cancelar</a>
                    </div>
                    <div class="col-6 col-sm-4">
                        <a (click)="onSave()" class="btn btn-block btn-secondary">Salvar</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



