<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Resgate</p>
        <div class="container">
            <h2 class="page_header_title">Resgate</h2>
        </div>
    </div>
</div>

<div class="container text-center" *ngIf="true">
    <div class="wait-panel">
        <h3>Em Breve</h3>
    </div>
</div>

<div class="container" *ngIf="false">
    <a class="btn btn-primary" (click)="showModal('modalRescueItems')">Meu Pedido</a>
    <div class="row">
        <div class="col-6">
            <h2>
                O prazo para resgate na vitrine de prêmios esgotou.
                Mas não se preocupe, seu valor foi convertido automaticamente em carga de gás, conforme regulamento.
            </h2>
            <p>
                NOSSA VITRINE DE PRÊMIOS ESTÁ MEGAINCRÍVEL!
                Veja só quantos prêmios legais nossas 175 revendas vencedoras poderão resgatar após a divulgação do
                resultado
                final da campanha. São dezenas de opções! É possível escolher carga de gás OU brindes OU um combinado
                dos dois.
                Lembrando, ainda, que a revenda que optar pelo resgate de brindes ganhará 50% a mais do valor em relação
                ao
                valor da carga de gás. Ganhou, escolheu!
            </p>
            <p>
                Resgate em Carga de Gás
                O resgate em carga de gás corresponde ao valor que será disponibilizado como crédito em sua carteira de
                cliente
                Thiago Henrique Personal Trainer, para futuras compras de abastecimento de botijões. Escolha a quantia conforme as opções abaixo
                e para
                compor o valor total desejado, clique no botão “+” para ir acrescentando mais valores até atingir a
                quantia
                desejada.
            </p>
            <div class="row mb-3">
                <div class="col-3" *ngFor="let i of [].constructor(4)">
                    <div class="bg-primary w-100" style="aspect-ratio: 1/1.3;"></div>
                </div>
            </div>
            <p>
                Resgate em brindes e itens de padronização
                Os itens dessa vitrine irão te ajudar na divulgação de sua revenda, e têm como objetivo incentivar suas
                vendas,
                divulgando a marca forte aos clientes e mantendo a preferência. Temos diversos itens que você poderá
                personalizar com o nome e telefones de contato da sua revenda, e distribuir aos seus consumidores. Para
                isto
                precisamos que você primeiramente cadastre os dados de sua(s) revenda(s) clicando no botão abaixo:
            </p>
            <div class="row" style="row-gap: 20px">
                <div class="col-3" *ngFor="let i of [].constructor(4)">
                    <div class="bg-primary w-100" style="aspect-ratio: 1/1.3;"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalRescueItems" tabindex="-1" role="dialog"
     aria-labelledby="loginLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">
                    <strong>MEU PEDIDO</strong>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">1</th>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                    </tr>
                    <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                    </tr>
                    <tr>
                        <th scope="row">3</th>
                        <td>Larry</td>
                        <td>the Bird</td>
                        <td>@twitter</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

